import { render, staticRenderFns } from "./CandidateRowRDED.vue?vue&type=template&id=271af4a3&scoped=true&lang=pug&"
import script from "./CandidateRowRDED.vue?vue&type=script&lang=js&"
export * from "./CandidateRowRDED.vue?vue&type=script&lang=js&"
import style0 from "./CandidateRowRDED.vue?vue&type=style&index=0&id=271af4a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "271af4a3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VCheckbox,VCol,VIcon,VMenu})
