<template lang="pug">
  tr(
    :key="index"
    :active="isSelected"
    :class="{'updated' : item.animation, 'app-table__row_expanded': isExpanded}"
    :style="{'background-color': candidateColor}"
    data-test="candidate-row"
  ).row-animation
    td
      v-checkbox(
        hide-details
        :value="isSelected"
        :key="index" @change="select"
        @click.native="selectWithShiftkey({event: $event, item: item, index: index})"
      ).mt-0
      div.row-labels
        label-primary(v-for="(label, index) in currentLabels" :key="index" :text="label.name" :color="label.color")


    td.app-table__cell.app-table__cell_small
      .d-flex
        v-icon(@click="copyData(item)").hoverable mdi-content-copy
        v-icon(
          :class="{'expanded': isExpanded}"
          @click="goToEdit({expand: expand, isExpanded: isExpanded})"
          data-test="candidate-open"
        ) mdi-chevron-right

    td(@click="goToEdit({expand: expand, isExpanded: isExpanded})" data-test="candidate-position") {{ positionInList }}

    td
      candidates-service
        candidate-color(:activeItems="[item]" :page="COLOR_PAGE")

    td(@click="goToEdit({expand: expand, isExpanded: isExpanded})")
      div.table-date.table-date_additional.text-center {{ item.appPlacedAtFormatted }}
      div.table-date.table-date_additional.text-center {{ item.appPaidAtFormatted }}

    td(:style="{maxWidth: '100px'}" @click="goToEdit({expand: expand, isExpanded: isExpanded})" data-test="candidate-name")
      | {{ item.fullName }}

    td(@click="goToEdit({expand: expand, isExpanded: isExpanded})")
      template(v-if="item.candidateNumber" )
        span(v-html="candidateNumberSplicedOnTwoElement").cdr
      candidate-label(:model="item")

    td.nowrap(@click="goToEdit({expand: expand, isExpanded: isExpanded})" data-test="candidate-bday")
      div {{ item.candidateBirthday }}
      div {{ item.candidateTel }}
    td
      accept-activator(:ID="item.ID" :model="item")
        template(v-slot:activator="{on}")
          span(v-on="on").hover-pointer {{ cbrAccount }}

    td(@click="goToEdit({expand, isExpanded})").text-center {{ item.applicationId }}

    td(@click="$emit('click:payments', item)" :class="{'has-package': hasPackage, 'not-has-package': notHasPackage}")
      check-color(:color="paymentColor")
      v-menu(top offset-y offset-x max-width="400" open-on-hover content-class="templates-tooltip")
        template(v-slot:activator="{ on }")
          span(v-on="on").hover-pointer.ml-1 {{ item.final_amount}} &euro;
        paymentsLog(:list="item.payments")
      div
        label-primary(text="Refunded" color="#ff0000" v-if="isRefunded")

    candidate-reserved-info(:item="item" @click="goToEdit({expand: expand, isExpanded: isExpanded})")

    td(@click="goToEdit({expand: expand, isExpanded: isExpanded})").text-center {{ item.exchange_count }}
    td(
      @click="goToEdit({expand: expand, isExpanded: isExpanded})"
      :style="{'background-color': licenseTypeColor}"
    ) {{ item.licenseType }}
    td(@click="goToEdit({expand: expand, isExpanded: isExpanded})") {{ item.productName }}
    td(@click="goToEdit({expand: expand, isExpanded: isExpanded})")
      TaskRowLanguageCell(:lang="item.language")
    td(@click="goToEdit({expand: expand, isExpanded: isExpanded})" data-test="cbr-status") {{ item.status }}

    td.text-center
      template(v-if="item.canceled")
        span Canceled
      template(v-else) -

    td(@click="goToEdit({expand: expand, isExpanded: isExpanded})" :style="{maxWidth: '150px'}").text-center
      template(v-if="item.lastError")
        show-more(:text="item.lastError" :height="'30px'")
      template(v-else) -

    td.col--course-type(v-html="item.course_type")

    td.package-td
      span(v-if="item && item.package_name.length") {{ item.package_name }}
      span(v-else) -
    td.duration-td {{ item.online_access_days_left}}
    td.duration-td {{ item.wiki_access_days_left }}
    td
      LogEmailProvider(:getLogs="getSentEmails" v-slot:default="emailLogs")
        baseEmailTemplateProvider
          template(
            v-slot:default="baseEmail"
          )
            EmailTemplatesSendProvider(
              :ID="item.ID"
              :model="item"
              :formErrors="baseEmail.formErrors"
              v-slot:default="sendProvider"
              @send="emailLogs.addToLogs"
            )
              EmailTemplates(
                :items="baseEmail.list"
                :itemsLoading="baseEmail.listLoading"
                :editedTemplate="baseEmail.editedTemplate"
                :loadData="baseEmail.loadData"
                :logs="baseEmail.logs"
                :formErrors="baseEmail.formErrors"
                :processing="baseEmail.processing"
                :maxItemsShowing="11"
                @templateSelected="baseEmail.loadTemplateData"
                @onAddCustomTemplate="baseEmail.loadCreateTemplate"
                @submit="sendProvider.sendEmail"
                @save="baseEmail.post"
                @update="baseEmail.update"
                @remove="baseEmail.remove"
              )
                template(v-slot:tooltip)
                  v-menu(left offset-x max-width="400" open-on-hover content-class="templates-tooltip" @input="emailLogs.setTemplateTooltipShowing")
                    template(v-slot:activator="{ on }")
                        v-icon(v-on="on") mdi-email
                    templatesLog(:list="emailLogs.logs")
                template(v-slot:side)
                  v-col
                    h3.email-templates__subtitle Email logs
                    templatesLog(:list="emailLogs.logs" :loading="emailLogs.logsLoading")

    td.table-dates
      course-confirmed(:item="item").cell-dates

    td.nowrap
      div(v-for="(request, index) in item.sortedFilteredRequests" :key="index").table-reservation
        reservation-date(
          :request="request"
          :filteredRequests="item.sortedFilteredRequests"
          :index="index"
          v-if="request.examDate && index < 2"
        )
    td.note-td
      note(:value="item.notes" :ID="item.ID")
</template>

<script>
import copyMixin from "@/mixins/copy.mixin"
import candidateRowMixin from "../../core/candidate-row.mixin"
import CourseConfirmed from "./CandidateCourseConfirmed.vue"
import CheckBoolean from "@/components/global/CheckBoolean.vue"
import restore from "../CandidateRestore.vue"
import isRefundedMixin from '@/mixins/isRefunded.mixin'
import CheckColor from "@/components/global/CheckColor.vue"
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

import { COLOR_PAGE } from '@/util/const'
import {CANDIDATES_LICENSE_TYPES_COLORS} from "@/app/admin/modules/candidates/core/candidates-const";
import TaskRowLanguageCell from "@/app/admin/modules/candidates/components/candidates/TaskRowLanguageCell.vue";

export default {
  mixins: [copyMixin, candidateRowMixin, isRefundedMixin, featureFlagsMixin],

  props: {
    isSelected: Boolean,
    isExpanded: Boolean,
    expand: Function,
    select: Function,
    item: {
      type: Object,
      requred: true
    },
    index: Number,
    pagination: Object,
    primaryPagination: Object,
    moreThanOnePageVisible: Boolean,
    activeItems: Array,
    labels: [Array, null]
  },

  computed: {
    hasPackage() {
      return (this.item.course_type === 'online' && this.item.has_package) || (this.item.course_type === 'offline' && this.item.has_package && this.item.is_course_paid);
    },

    notHasPackage() {
      return (this.item.course_type === 'online' && !this.item.has_package ) || (this.item.course_type === 'offline' && !this.item.has_package && this.item.is_course_paid);
    },

    licenseTypeColor() {
      return CANDIDATES_LICENSE_TYPES_COLORS[this.item.licenseType] || null;
    }
  },

  data: () => ({
    COLOR_PAGE: COLOR_PAGE.CANDIDATES
  }),

  components: {
    TaskRowLanguageCell,
    candidatesService: () => import('@/app/admin/modules/candidates/components/CandidatesProvider.vue'),
    candidateLabel: () => import('./CandidatesLabel.vue'),
    acceptActivator: () => import('../CandidateAcceptActivator.vue'),
    candidateArchive: () => import('../CandidatesArchive.vue'),
    reservationDate: () => import('./CandidatesReservedDate.vue'),
    note: () => import('./CandidatesNote.vue'),
    EmailTemplatesProvider: () => import('./CandidatesEmailTemplatesProvider.vue'),
    EmailTemplates: () => import('./CandidatesEmailTemplates'),
    baseEmailTemplateProvider: () => import('./CandidatesEmailBaseProvider.vue'),
    EmailTemplatesSendProvider: () => import('./CandidatesEmailSendProvider.vue'),
    LogEmailProvider: () => import('./CandidateEmailLogProvider.vue'),
    templatesLog: () => import('./CandidatesETempLog.vue'),
    paymentsLog: () => import('../payments/CandidatesPaymentLog.vue'),
    checkColor: CheckColor,
    CourseConfirmed: CourseConfirmed,
    restore,
    showMore: () => import('@/components/global/showMoreTooltip.vue'),
    labelPrimary: () => import('@/components/global/LabelPrimary.vue'),
    candidateColor: () => import('@/app/admin/modules/candidates/components/CandidateColor.vue'),
    candidateReservedInfo: () => import('@/app/admin/modules/course_day/components/body/CandidateReservedInfo.vue'),
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../../../src/app/admin/modules/candidates/assets/candidates.scss";

.table-dates {
  width: 170px;
  line-height: 1.3;
}

.table-reservation {
  margin: 0 -4px;
}

.app-table td {
  font-size: 12px;
  line-height: 1.3;
}

.app-table__row_expanded {
  > td {
    border-top: 1px solid $primary-color;

    &:first-child {
      border-left: 1px solid $primary-color;
    }

    &:last-child {
      border-right: 1px solid $primary-color;
    }
  }
}

.note-td {
  max-width: 150px;
}

.cell--course-type {
  max-width: 80px;
}

.package-td {
  text-align: center;
}

.duration-td {
  text-align: center;
}

.row-labels {
  position: absolute;
  margin-top: 5px;
}
</style>
